import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 引入字体样式
import "@/assets/font/font.css"

createApp(App).use(ElementPlus).use(store).use(router).mount('#app')
import Vue from 'vue'


